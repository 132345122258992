import { EditOneOperationBuilder } from "@kraaft/offline";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { fileUpload } from "@kraaft/shared/core/modules/file/fileUploader";
import { incrementPendingOperations } from "@kraaft/shared/core/modules/modularFolder/operations/modularFolder.offline.tools";
import { LongTextColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/longText";
import { ShortTextColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/shortText";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export const fillFromAudioOperation =
  EditOneOperationBuilder.create<ModularFolder>()
    .payload<{
      userId: string;
      audioFile: ModernFile<LocalPath>;
    }>()
    .expected((modularFolder) => {
      incrementPendingOperations(modularFolder);

      modularFolder.pending = {
        ...modularFolder.pending,
        isFillingWithAudio: true,
      };

      for (const columnKey of Object.keys(modularFolder.properties)) {
        const property = modularFolder.properties[columnKey];
        if (property) {
          if (
            [KColumnType.longText, KColumnType.shortText].includes(
              property.columnType,
            )
          ) {
            modularFolder.properties[columnKey] = {
              ...(property as LongTextColumnValue | ShortTextColumnValue),
              value: i18n.t("fillWithAudio.loadingText"),
            };
          }
        }
      }

      return modularFolder;
    })
    .mutate(async (payload, task) => {
      const audioStoragePath = await fileUpload.uploadToUserSpace(
        payload.audioFile,
        payload.userId,
      );

      const { updatedAt } = await Api.fillModularFolderWithAudio({
        requestId: task.id,
        modularFolderId: payload.id,
        audioStoragePath,
      });
      return { updatedAt };
    });
