import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useCountryCodes } from "@kraaft/shared/components/auth/countryPicker/countryCodeUtils";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { SearchBar } from "@kraaft/shared/components/searchBar";
import { Sheet } from "@kraaft/ui";

import { styles } from "./countryPicker.styles";

export const CountryPickerSheet = ({
  selectedCode,
  onChangeCode,
}: {
  selectedCode: string;
  onChangeCode: (value: string) => void;
}) =>
  Sheet({
    default: "anchored",
  })
    .create(({ Paper, Content }) => ({ onClose }) => {
      return (
        <Paper>
          <Content noPadding>
            <CountryPickerSheetContent
              selectedCode={selectedCode}
              onChangeCode={onChangeCode}
              onClose={onClose}
            />
          </Content>
        </Paper>
      );
    })
    .withDefaults({ withoutBackdrop: true });

const CountryPickerSheetContent = ({
  selectedCode,
  onChangeCode,
  onClose,
}: {
  selectedCode: string;
  onChangeCode: (value: string) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const { countryCodeItems, countryCodeMap, dialCodeMap } = useCountryCodes();

  const [filterText, setFilterText] = useState("");

  const selected = useMemo(() => {
    const countryCode = dialCodeMap[selectedCode]?.code;
    return countryCode ? [countryCode] : [];
  }, [dialCodeMap, selectedCode]);

  const clearFilter = useCallback(() => {
    setFilterText("");
  }, []);

  const setSelected = useCallback(
    (value: string[]) => {
      onChangeCode((value[0] && countryCodeMap[value[0]]?.dial_code) ?? "");
      onClose();
    },
    [countryCodeMap, onChangeCode, onClose],
  );
  return (
    <View style={styles.sheetContent}>
      <View style={styles.filter}>
        <SearchBar
          value={filterText}
          onChange={setFilterText}
          placeholder={t("search")}
          onClearSearch={clearFilter}
          isStatic
        />
      </View>
      <KSelectionList
        items={countryCodeItems}
        selected={selected}
        setSelected={setSelected}
        labelFilter={filterText}
        minSelection={1}
        showChecker={false}
        keyboardShouldPersistTaps="handled"
      />
    </View>
  );
};
