import React, { ReactNode } from "react";
import { makeStyles } from "@mui/styles";

import { styleObject } from "@kraaft/shared/styles/panel.styles";
import { Color, Radius, Spacing } from "@kraaft/ui";

const useStyles = makeStyles({
  box: {
    display: "flex",
    position: "relative",
    flexDirection: (props: BoxProps) => (props.row ? "row" : "column"),
    backgroundColor: (props: BoxProps) =>
      props.bg ? Color[props.bg] : undefined,
    flexGrow: (props: BoxProps) => (props.grow ? 1 : undefined),
    flexShrink: (props: BoxProps) => (props.grow ? 0 : undefined),
    flexBasis: (props: BoxProps) => (props.grow ? 0 : undefined),
    gap: (props: BoxProps) => (props.gap ? Spacing[props.gap] : undefined),
    overflow: (props: BoxProps) => props.overflow ?? undefined,
    justifyContent: (props: BoxProps) => props.justify,
    alignItems: (props: BoxProps) => props.items,
    marginLeft: (props: BoxProps) => Spacing[props.ml || props.mh || "NONE"],
    marginRight: (props: BoxProps) => Spacing[props.mr || props.mh || "NONE"],
    marginTop: (props: BoxProps) => Spacing[props.mt || props.mv || "NONE"],
    marginBottom: (props: BoxProps) => Spacing[props.mb || props.mv || "NONE"],
    paddingLeft: (props: BoxProps) => Spacing[props.pl || props.ph || "NONE"],
    paddingRight: (props: BoxProps) => Spacing[props.pr || props.ph || "NONE"],
    paddingTop: (props: BoxProps) => Spacing[props.pt || props.pv || "NONE"],
    paddingBottom: (props: BoxProps) => Spacing[props.pb || props.pv || "NONE"],
    borderBottomColor: (props: BoxProps) =>
      props.bb ? styleObject.borderBottom.borderBottomColor : Color.TRANSPARENT,
    borderBottomWidth: (props: BoxProps) =>
      props.bb ? styleObject.borderBottom.borderBottomWidth : 0,
    borderBottomStyle: (props: BoxProps) =>
      props.bb ? styleObject.borderBottom.borderBottomStyle : "none",
    borderTopColor: (props: BoxProps) =>
      props.bt ? styleObject.borderTop.borderTopColor : Color.TRANSPARENT,
    borderTopWidth: (props: BoxProps) =>
      props.bt ? styleObject.borderTop.borderTopWidth : 0,
    borderTopStyle: (props: BoxProps) =>
      props.bt ? styleObject.borderTop.borderTopStyle : "none",
    borderRadius: (props: BoxProps) =>
      props.radius ? Radius[props.radius] : undefined,
    height: (props: BoxProps) => (props.height ? props.height : undefined),
    minWidth: (props: BoxProps) => props.minWidth,
  },
});

interface BoxProps {
  children: ReactNode;
  grow?: boolean;
  bg?: keyof typeof Color;
  radius?: keyof typeof Radius;
  row?: boolean;
  overflow?: "auto" | "hidden" | "visible" | "scroll";
  justify?: "space-between" | "flex-end" | "flex-start" | "center";
  items?: "center" | "flex-start" | "flex-end";
  // flex gap
  gap?: keyof typeof Spacing;
  // margin left
  ml?: keyof typeof Spacing;
  // margin right
  mr?: keyof typeof Spacing;
  // margin horizontal
  mh?: keyof typeof Spacing;
  // margin top
  mt?: keyof typeof Spacing;
  // margin bottom
  mb?: keyof typeof Spacing;
  // margin vertical
  mv?: keyof typeof Spacing;
  // padding left
  pl?: keyof typeof Spacing;
  // padding right
  pr?: keyof typeof Spacing;
  // padding horizontal
  ph?: keyof typeof Spacing;
  // padding top
  pt?: keyof typeof Spacing;
  // padding bottom
  pb?: keyof typeof Spacing;
  // padding vertical
  pv?: keyof typeof Spacing;
  // border top
  bt?: boolean;
  // border bottom
  bb?: boolean;
  height?: React.CSSProperties["height"];
  debugBorderColor?: string;
  minWidth?: React.CSSProperties["minWidth"];
}

export const Box = React.memo((props: BoxProps) => {
  const classes = useStyles(props);

  return (
    <div
      className={classes.box}
      style={
        props.debugBorderColor
          ? { border: `3px solid ${props.debugBorderColor}` }
          : undefined
      }
    >
      {props.children}
    </div>
  );
});
