import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

import { useHover, useMeshContextSetup } from "@kraaft/helper-hooks";
import { PoolAddonsSelectors } from "@kraaft/shared/core/modules/poolAddons";
import { useSubscribeToPoolAddons } from "@kraaft/shared/core/modules/poolAddons/hooks/useSubscribeToPoolAddons";
import { selectCurrentContextAtLeastAdminOrSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { displaySandbox } from "@kraaft/shared/core/utils";
import { Color, ColorStyle, Spacing } from "@kraaft/ui";
import { CollapsibleNavigationBarElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/collapsibleNavigationBarElement";
import { HelpCenterElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/helpCenterElement";
import { LogoutElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/logoutElement";
import { NavigationButtonElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/navigationButtonElement";
import { PlanningWaitingListNavElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/planningWhitelistNavElement";
import { PoolSelectionElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/poolSelectionElement";
import {
  VerticalNavigationContext,
  VerticalNavigationContextValue,
} from "@kraaft/web/src/components/layout/verticalNavigationBar/verticalNavigation.context";
import {
  VERTICAL_NAVBAR_EXPANDED_MAX_WIDTH,
  VERTICAL_NAVBAR_WIDTH,
} from "@kraaft/web/src/components/layout/verticalNavigationBar/verticalNavigation.utils";
import { WhatsNewElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/whatsNewElement";
import { routes } from "@kraaft/web/src/views/app/appRouter/routes";

import { LibrarySchemaNavigationButton } from "./librarySchemaNavigationButton";

const ELEMENT_MAX_WIDTH = VERTICAL_NAVBAR_EXPANDED_MAX_WIDTH - Spacing.S8;

const VerticalNavigation = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isAtLeastAdminOrSuperadmin = useSelector(
    selectCurrentContextAtLeastAdminOrSuperadmin,
  );

  useSubscribeToPoolAddons();

  const planningEnabled = useSelector(
    PoolAddonsSelectors.selectPlanningEnabled,
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const [canExpand, setExpanded] = useState(false);
  const [isPoolSelectionOpened, setPoolSelectionOpened] = useState(false);

  useHover(containerRef, setExpanded);

  const isExpanded = isPoolSelectionOpened || canExpand;
  const value = useMeshContextSetup<VerticalNavigationContextValue>({
    isExpanded,
    overrideExpanded: setExpanded,
    isPoolSelectionOpened,
    setPoolSelectionOpened,
  });

  const content = useMemo(
    () => (
      <div ref={containerRef} className={classes.container}>
        <div className={classes.overlayContent}>
          <CollapsibleNavigationBarElement>
            <div className={classes.maxWidthElement} />
          </CollapsibleNavigationBarElement>
          <PoolSelectionElement />
          <div className={classes.scrollable}>
            <div className={classes.buttonsContainer}>
              <NavigationButtonElement
                id="navbar-conversations"
                icon="message-circle-01"
                text={t("rooms")}
                route={routes.Messenger}
              />
              {isAtLeastAdminOrSuperadmin && (
                <>
                  <NavigationButtonElement
                    id="navbar-form-builder-schemas"
                    icon="form-builder"
                    text={t("formBuilder.nav.folderSchema")}
                    route={routes.SchemasView}
                    excludeRoute={[routes.RoomSchemaBuilder]}
                  />
                  <NavigationButtonElement
                    id="navbar-database"
                    icon="table"
                    text={t("database")}
                    route={routes.Database}
                    matchRoute={[
                      routes.SchemaView,
                      routes.ModularFoldersTableForRoom,
                      routes.ModularFoldersTable,
                      routes.Database,
                    ]}
                  />
                  <NavigationButtonElement
                    id="navbar-workflows"
                    icon="flash"
                    text={t("menuWorkflows")}
                    route={routes.ManageWorkflows}
                  />
                  <LibrarySchemaNavigationButton />
                  {!planningEnabled && <PlanningWaitingListNavElement />}
                  {planningEnabled && (
                    <NavigationButtonElement
                      id="navbar-planning"
                      icon="calendar"
                      text={t("planning.navigationItem")}
                      route={routes.Planning}
                    />
                  )}
                </>
              )}
              {displaySandbox && (
                <NavigationButtonElement
                  icon="code-square-01"
                  text="Sandbox"
                  route={routes.Sandbox}
                />
              )}
            </div>
            <div id="navbar-tutorial-anchor" />
            <div className={classes.spacer} />
            <div className={classes.buttonsContainer}>
              <NavigationButtonElement
                id="navbar-settings"
                icon="settings-02"
                text={t("menuParameters")}
                route={routes.Settings}
              />
              <WhatsNewElement />
              <HelpCenterElement />
              <LogoutElement />
            </div>
          </div>
        </div>
      </div>
    ),
    [
      classes.buttonsContainer,
      classes.container,
      classes.maxWidthElement,
      classes.overlayContent,
      classes.scrollable,
      classes.spacer,
      isAtLeastAdminOrSuperadmin,
      planningEnabled,
      t,
    ],
  );

  return (
    <VerticalNavigationContext.Provider value={value}>
      {content}
    </VerticalNavigationContext.Provider>
  );
};

const useStyles = makeStyles({
  container: {
    position: "relative",
    display: "flex",
    width: VERTICAL_NAVBAR_WIDTH,
    minWidth: VERTICAL_NAVBAR_WIDTH,
    zIndex: 1,
  },

  overlayContent: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,

    width: "max-content",

    display: "flex",
    flexDirection: "column",

    backgroundColor: Color.WHITE,
    maxWidth: VERTICAL_NAVBAR_EXPANDED_MAX_WIDTH,
    borderRightColor: ColorStyle.SEPARATOR,
    borderRightWidth: 1,
    borderRightStyle: "solid",
    boxSizing: "border-box",

    padding: `${Spacing.S16}px ${Spacing.S8}px`,
  },

  scrollable: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },

  maxWidthElement: {
    width: ELEMENT_MAX_WIDTH,
  },

  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: Spacing.S12,
  },

  spacer: {
    flexGrow: 1,
    minHeight: Spacing.S12,
  },
});

export { VerticalNavigation };
