import { FIRESTORE_VERBOSE } from "@kraaft/shared/constants/global";
import {
  firestore,
  FirestoreTypes,
} from "@kraaft/shared/core/services/firestore/sdk";

export class FirestoreUtils {
  static getFirestoreCollection<T extends FirestoreTypes.DocumentData>(
    collectionPath: string,
  ) {
    return firestore().collection(
      collectionPath,
    ) as FirestoreTypes.CollectionReference<T>;
  }
}

export function onSnapshotQuery<T extends FirestoreTypes.DocumentData>(
  message: string,
  query: FirestoreTypes.Query<T>,
  onNext: (snapshot: FirestoreTypes.QuerySnapshot<T>) => void,
  onError?: (error: FirestoreTypes.FirestoreError) => void,
  shouldUpdateOnMetadataUpdate?: boolean,
) {
  const startDate = new Date();
  if (FIRESTORE_VERBOSE) {
    console.log("subscribing to", message);
  }

  const unsubscribe = query.onSnapshot(
    { includeMetadataChanges: shouldUpdateOnMetadataUpdate ?? false },
    (snapshot) => {
      if (snapshot) {
        if (FIRESTORE_VERBOSE) {
          const durationMs = new Date().getTime() - startDate.getTime();
          console.log(
            `snapshot received for ${message}: ${snapshot.size} docs cache=${snapshot.metadata.fromCache} duration= ${durationMs / 1000} s, ${JSON.stringify(snapshot).length.toLocaleString()} chars`,
          );
        }
        onNext(snapshot);
      } else {
        const durationMs = new Date().getTime() - startDate.getTime();
        // If no error callback is specified, Firestore will send a null snapshot
        if (FIRESTORE_VERBOSE) {
          console.log(
            `snapshot received for ${message}: ${snapshot} duration= ${durationMs / 1000} s, ${JSON.stringify(snapshot).length.toLocaleString()} chars`,
          );
        }
      }
    },
    (error) => {
      console.warn(`snapshot error for ${message}`, error);
      onError?.(error);
    },
  );

  return () => {
    if (FIRESTORE_VERBOSE) {
      console.log("unsubscribing from", message);
    }
    unsubscribe();
  };
}
