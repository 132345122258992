import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CompanyDropdown } from "@kraaft/shared/core/modules/company/components/companyDropdown";
import { getAddLibrarySchemaLoaderId } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions.utils";
import { useSubscribeToLibrarySchemas } from "@kraaft/shared/core/modules/librarySchema/librarySchema.hooks";
import { OfflineLibrarySchemaActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offline";
import { selectOrderedLibrarySchemas } from "@kraaft/shared/core/modules/librarySchema/librarySchema.selectors";
import {
  LibrarySchema,
  LibrarySchemaLanguage,
} from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { ManageSchemaLibraryTagsButton } from "@kraaft/shared/core/modules/schemaLibraryTag/components/manageSchemaLibraryTagsButton";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { useLoaderState } from "@kraaft/shared/core/utils/useLoader";
import { Button } from "@kraaft/ui";
import {
  KNavBar,
  KNavBarTabProps,
} from "@kraaft/web/src/components/kNavBar/kNavBar";
import { OrderableList } from "@kraaft/web/src/components/orderableList";
import { OrderedListRows } from "@kraaft/web/src/components/orderableList/orderableList.types";
import { prepareForOrderableList } from "@kraaft/web/src/components/orderableList/orderableList.utils";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { AskLibrarySchemaNameAndIconDialog } from "@kraaft/web/src/views/settings/librarySchemaList/askLibrarySchemaNameAndIconDialog";
import { generateTabForLocale } from "@kraaft/web/src/views/settings/librarySchemaList/librarySchemaList.utils";
import { OrderableLibrarySchemaItem } from "@kraaft/web/src/views/settings/librarySchemaList/orderableLibrarySchemaItem/orderableLibrarySchemaItem";
import { useSettingsStyles } from "@kraaft/web/src/views/settings/settings.styles";

import { useStyles } from "./librarySchemaList.styles";

export const LibrarySchemaList = () => {
  const { t } = useTranslation();
  const settingsClasses = useSettingsStyles();
  const classes = useStyles();
  const dispatch = useDispatch();

  useSubscribeToLibrarySchemas();

  const librarySchemas = useSelector(selectOrderedLibrarySchemas);

  const { loading: isAddButtonLoading } = useLoaderState(
    getAddLibrarySchemaLoaderId(),
  );

  const [
    isAddLibrarySchemaDialogOpen,
    openAddLibrarySchemaDialog,
    closeAddLibrarySchemaDialog,
  ] = useBooleanState(false);

  const [selectedLanguage, setSelectedLanguage] =
    useState<LibrarySchemaLanguage>("fr-FR");

  const tabs: KNavBarTabProps[] = useMemo(() => {
    return [
      generateTabForLocale("fr-FR", t),
      generateTabForLocale("en-US", t),
      generateTabForLocale("en-GB", t),
      generateTabForLocale("it-IT", t),
      generateTabForLocale("de-DE", t),
      generateTabForLocale("es-ES", t),
    ];
  }, [t]);

  const [byCompany, setByCompany] = useState<string | undefined>(undefined);

  const orderableLibrarySchemas = useMemo(
    () =>
      prepareForOrderableList(
        librarySchemas.filter(
          (librarySchema) =>
            librarySchema.language === selectedLanguage &&
            (!byCompany || byCompany === librarySchema.companyId),
        ),
        (librarySchema) => ({
          key: librarySchema.id,
          index: librarySchema.index,
          data: librarySchema,
        }),
      ),
    [byCompany, librarySchemas, selectedLanguage],
  );

  const renderRow = useCallback((librarySchema: LibrarySchema) => {
    return <OrderableLibrarySchemaItem librarySchema={librarySchema} />;
  }, []);

  const handleChange = useCallback((newValue: string) => {
    setSelectedLanguage(newValue as LibrarySchemaLanguage);
  }, []);

  const updateLibrarySchemas = useCallback(
    (newLibrarySchemas: OrderedListRows<LibrarySchema>) => {
      const sorted = Object.entries(newLibrarySchemas)
        .sort((left, right) => left[1].index - right[1].index)
        .map(([key]) => key);

      dispatch(
        OfflineLibrarySchemaActions.reorder({
          language: selectedLanguage,
          orderedIds: sorted,
        }),
      );
    },
    [dispatch, selectedLanguage],
  );

  const disableDragging = Boolean(byCompany);

  return (
    <div className={settingsClasses.pageContainer}>
      <PageHeader title={t("schemaLibrary.configure.label")} />
      <div className={classes.panelsContainer}>
        <KNavBar value={selectedLanguage} onChange={handleChange} tabs={tabs} />
        <div className={classes.filterRow}>
          <div className={classes.filterCompany}>
            <CompanyDropdown
              value={byCompany}
              onChange={setByCompany}
              placeholder={t("support.filterByCompany")}
            />
          </div>
          <ManageSchemaLibraryTagsButton language={selectedLanguage} />
        </div>
        <div className={classes.listContainer}>
          <OrderableList
            rows={orderableLibrarySchemas}
            updateRows={updateLibrarySchemas}
            renderRow={renderRow}
            rowContainerClassName={classes.rowContainer}
            withHandle={!disableDragging}
            disabled={disableDragging}
          />
          <div className={classes.addButtonContainer}>
            <Button
              variant="PRIMARY"
              accessibilityLabel={t("schemaLibrary.configure.newLibrarySchema")}
              text={t("schemaLibrary.configure.newLibrarySchema")}
              icon="plus"
              onPress={openAddLibrarySchemaDialog}
              loading={isAddButtonLoading}
            />
            <AskLibrarySchemaNameAndIconDialog
              open={isAddLibrarySchemaDialogOpen}
              onClose={closeAddLibrarySchemaDialog}
              language={selectedLanguage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
