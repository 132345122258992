import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";

import { Pill } from "@kraaft/shared/components/pill";
import { showSuccess } from "@kraaft/shared/core/modules/alert/alertActions";
import { PlanningWaitingListStateActions } from "@kraaft/shared/core/modules/surveys/planningWaitingList/redux";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import {
  Button,
  Color,
  ColorStyle,
  Image,
  Sheet,
  Spacing,
  Text,
} from "@kraaft/ui";

import blurryPlanningWeb from "./assets/blurryPlanningWeb.png";

export type PlanningWaitingListSheetProps = {};

export const PlanningWaitingListSheet = Sheet({
  web: "popup",
})
  .create<PlanningWaitingListSheetProps>(
    ({ Paper, Content }) =>
      ({ onClose }) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();

        const handleOptIn = useCallback(() => {
          trackEvent({
            eventName: "Opt In Planning Waiting List",
          });
          dispatch(PlanningWaitingListStateActions.hide());
          dispatch(showSuccess({ title: t("planning.waitingList.success") }));
          onClose();
        }, [dispatch, onClose, t]);

        return (
          <Paper>
            <Content>
              <View style={styles.container}>
                <Text weight="bold" size="TITLE" style={styles.centeredText}>
                  {t("planning.waitingList.arriving")}
                </Text>
                <Text size="BODY" style={styles.centeredText}>
                  {t("planning.waitingList.description")}
                </Text>
                <View style={styles.content}>
                  <View style={styles.imageContainer}>
                    <Image source={blurryPlanningWeb} style={styles.image} />

                    <View style={styles.pillContainer}>
                      <Pill
                        color={Color.YELLOW_KRAAFT}
                        icon="hourglass-03"
                        content={t("planning.waitingList.comingSoon")}
                        textColor={ColorStyle.FONT_HIGH_EMPHASIS}
                        size="medium"
                      />
                    </View>
                  </View>
                  <Button
                    text={t("planning.waitingList.join")}
                    onPress={handleOptIn}
                  />
                </View>
              </View>
            </Content>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "MEDIUM" });

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: Spacing.S16,
  },
  centeredText: {
    textAlign: "center",
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: Spacing.S16,
  },
  imageContainer: {
    position: "relative",
  },
  image: {
    height: 150,
  },
  pillContainer: {
    ...StyleSheet.absoluteFillObject,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
