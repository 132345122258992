import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { useAlert } from "@kraaft/shared/core/modules/alert/useAlert";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api/api";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button, ColorStyle, Spacing, Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { DateInput } from "@kraaft/web/src/components/dateInput/dateInput";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { Toggle } from "@kraaft/web/src/components/toggle";
import { UrlLink } from "@kraaft/web/src/views/auth/urlLink";

import { useSettingsStyles } from "../settings.styles";

type ValidApiMethods = {
  [C in keyof typeof Api]: Parameters<(typeof Api)[C]>[1] extends undefined
    ? C
    : never;
}[keyof typeof Api];

export const KAPI = {
  useCommand<C extends ValidApiMethods>(
    endpoint: C,
    refetch: (() => any)[] = [],
  ): UseMutationResult<
    unknown,
    unknown,
    Parameters<(typeof Api)[C]>[0],
    unknown
  >["mutate"] {
    const alert = useAlert();
    const { mutate } = useMutation(
      [endpoint] as const,
      (variables: Parameters<(typeof Api)[C]>[0]) =>
        Api[endpoint](variables as any),
      {
        onSuccess: () => {
          for (const fn of refetch) {
            fn();
          }
        },
        onError(error, variables, context) {
          alert.alertError(error as any);
        },
      },
    );

    return mutate;
  },
  useQuery<Q extends ValidApiMethods>(
    endpoint: Q,
    getVariables: () => Parameters<(typeof Api)[Q]>[0] | undefined | "",
  ): UseQueryResult<Awaited<ReturnType<(typeof Api)[Q]>>> {
    const variables = getVariables();

    return useQuery(
      [endpoint, variables] as const,
      () => Api[endpoint](variables as any),
      {
        enabled: !!variables,
      },
    );
  },
};

function useBilling(poolId?: string) {
  const { data: result, refetch } = KAPI.useQuery(
    "getBillingInformation",
    () => poolId && { poolId },
  );

  const setPoolTrial = KAPI.useCommand("setPoolTrial", [refetch]);
  const setRestriction = KAPI.useCommand("setPoolRestrictionScheme", [refetch]);
  const setSubscriptionId = KAPI.useCommand("setPoolStripeSubscriptionId", [
    refetch,
  ]);

  const pullSubscriptionStatus = KAPI.useCommand(
    "pullStripeSubscriptionStatus",
    [refetch],
  );
  const pushSubscriptionLicences = KAPI.useCommand(
    "pushStripeSubscriptionLicences",
    [refetch],
  );

  const enableProration = KAPI.useCommand("enableStripeProration", [refetch]);
  const disableProration = KAPI.useCommand("disableStripeProration", [refetch]);

  const showPortal = KAPI.useCommand("showStripeCustomerPortal", [refetch]);
  const hidePortal = KAPI.useCommand("hideStripeCustomerPortal", [refetch]);

  const recomputeBilling = KAPI.useCommand("recomputeBilling", [refetch]);

  const subscriptionId = result?.stripeSubscriptionId;

  return {
    result: result && {
      ...result,
      trialPeriodEnd: result?.trialPeriodEnd
        ? new Date(result.trialPeriodEnd)
        : undefined,
    },
    setPoolTrial: (trialPeriodEnd: Date | undefined) => {
      if (poolId) {
        setPoolTrial({ poolId, trialEnd: trialPeriodEnd });
      }
    },
    setRestriction: (
      restrictionScheme: "automatic" | "restricted" | "unrestricted",
    ) => {
      if (poolId) {
        setRestriction({ poolId, restrictionScheme });
      }
    },
    setSubscriptionId: (newId?: string) => {
      if (poolId) {
        if (!newId) {
          return setSubscriptionId({ poolId, stripeSubscriptionId: undefined });
        }
        return setSubscriptionId({
          poolId,
          stripeSubscriptionId: newId,
        });
      }
    },
    recomputeBilling: () => {
      if (!poolId) {
        return;
      }

      recomputeBilling({ poolId });
    },
    subscription: {
      toggleProration: () => {
        if (!subscriptionId) {
          return;
        }

        if (result.stripeSubscriptionProrateChanges) {
          disableProration({ subscriptionId });
        } else {
          enableProration({ subscriptionId });
        }
      },
      togglePortal: () => {
        if (!subscriptionId) {
          return;
        }

        if (result.stripeSubscriptionCustomerPortalEnabled) {
          hidePortal({ subscriptionId });
        } else {
          showPortal({ subscriptionId });
        }
      },
      pullStatus: () => {
        if (!subscriptionId) {
          return;
        }

        pullSubscriptionStatus({ subscriptionId });
      },
      pushLicences: () => {
        if (!subscriptionId) {
          return;
        }

        pushSubscriptionLicences({ subscriptionId });
      },
    },
  };
}

const ManageBilling = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const settingsClasses = useSettingsStyles();

  const poolId = useSelector(selectCurrentPoolId);

  useTrackPage("ManageBilling");

  const isSuperAdmin = useSelector(selectCurrentUserIsSuperadmin);

  const {
    result,
    setPoolTrial,
    setRestriction,
    setSubscriptionId,
    subscription,
    recomputeBilling,
  } = useBilling(poolId);

  const hasStripeSubscription = result?.stripeSubscriptionId;
  const isFree = !result?.licenses;

  return (
    <div className={settingsClasses.pageContainer}>
      <PageHeader title={t("settingsBilling")} />
      {hasStripeSubscription ? (
        result.stripeSubscriptionCustomerPortalEnabled ? (
          <Button
            icon="shield-dollar"
            accessibilityLabel={t("billing.stripeButton")}
            text={t("billing.stripeButton")}
            onPress={() => {
              window.open(result?.stripeCustomerPortalUrl, "_blank");
            }}
          />
        ) : (
          <Text>{t("billing.stripeConfigured")}</Text>
        )
      ) : !result ? null : isFree ? (
        <Text>{t("billing.workspaceFree")}</Text>
      ) : (
        <Text>{t("billing.stripeNotConfigured")}</Text>
      )}
      {isSuperAdmin && (
        <section className={classes.superadminSection}>
          <Box row justify="center">
            <Text size="H1" weight="bold">
              Support
            </Text>
          </Box>
          <DateInput
            label={t("billing.trialInput")}
            value={result?.trialPeriodEnd}
            onChange={setPoolTrial}
          />
          <KDropdown
            variant="dark"
            selectedItemIds={[result?.restrictionScheme || "automatic"]}
            items={[
              {
                label: `Restriction: Automatique (${result?.billingRestriction})`,
                value: "automatic",
              },
              {
                label: "Restriction: Restreint",
                value: "restricted",
              },
              {
                label: "Restriction: Non Restreint",
                value: "unrestricted",
              },
            ]}
            onSelectionChange={(ids) => ids?.[0] && setRestriction(ids[0])}
          />
          <KInput
            label={t("billing.subscriptionId")}
            placeholder={t("billing.subscriptionId")}
            defaultValue={result?.stripeSubscriptionId}
            onChangeText={() => {}}
            onBlur={(e) => setSubscriptionId(e?.nativeEvent.text)}
            showEditIcon
          />
          {result?.stripeSubscriptionId && (
            <Accordion defaultExpanded>
              <AccordionSummary>
                <Text size="SUBTITLE">
                  Stripe Subscription: {result?.stripeSubscriptionId}
                </Text>
                <UrlLink
                  url={`https://dashboard.stripe.com/subscriptions/${result?.stripeSubscriptionId}`}
                  text="Open In Stripe"
                />
              </AccordionSummary>
              <AccordionDetails>
                <Box gap="S4">
                  <Text>Status: {result?.stripeSubscriptionStatus}</Text>
                  <Box items="center" justify="flex-start" row gap="S8">
                    <Text>Show stripe portal link</Text>
                    <Toggle
                      value={!!result?.stripeSubscriptionCustomerPortalEnabled}
                      setValue={subscription.togglePortal}
                    />
                    <Text size="MINI">
                      Workspace owners will have access to the stripe portal
                      with payments and invoices
                    </Text>
                  </Box>
                  <Box items="center" justify="flex-start" row gap="S8">
                    <Text>EXPERIMENTAL: Activate Pro Rata</Text>
                    <Toggle
                      value={!!result?.stripeSubscriptionProrateChanges}
                      setValue={subscription.toggleProration}
                    />
                    <Text size="MINI">
                      Ensure users are charged proportionally to the duration of
                      their membership.
                    </Text>
                  </Box>
                </Box>
              </AccordionDetails>
              <AccordionActions>
                <Text>Manual Actions in case of desync:</Text>
                <Button
                  size="SMALL"
                  icon="download-cloud-01"
                  text="Pull Stripe Status"
                  onPress={subscription.pullStatus}
                />
                <Button
                  size="SMALL"
                  icon="upload-cloud-01"
                  text="Push Licences to Stripe"
                  onPress={subscription.pushLicences}
                />
                <Button
                  size="SMALL"
                  icon="file-question-03"
                  text="Recompute licenses"
                  onPress={recomputeBilling}
                />
              </AccordionActions>
            </Accordion>
          )}
        </section>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  superadminSection: {
    display: "flex",
    flexDirection: "column",
    gap: Spacing.S8,
    marginTop: Spacing.S16,
    paddingTop: Spacing.S16,
    borderTop: `1px solid ${ColorStyle.SEPARATOR}`,
  },
});

export { ManageBilling };
