import { Branded } from "@kraaft/helper-types";
import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { ImageQuality } from "@kraaft/shared/core/modules/file/imageHelper";
import { GeoCoordinates } from "@kraaft/shared/core/types";
import { PlatformSelect } from "@kraaft/shared/core/utils/platformSelect/platformSelect";

/**
 * The base FilePath, every file path should inherit from this.
 */
export type FilePath = Branded<string, "path">;

/**
 * An on-device file. Accessible with the file:// scheme.
 */
export type DevicePath = Branded<FilePath, "device">;
/**
 * A web blob path. Accessible with the blob: scheme.
 */
export type BrowserPath = Branded<FilePath, "browser">;

/**
 * A remote http path, Accessible with the http:// or https:// scheme.
 */
export type RemotePath = Branded<FilePath, "remote">;
/**
 * A remote http path that supports resizing, Accessible with the http:// or https:// scheme
 * and resizable with query params.
 */
export type ResizablePath = Branded<RemotePath, "resizable">;
/**
 * Unknown path, we don't know what it refers to.
 */
export type UnknownPath = Branded<RemotePath, "unknown">;

/**
 * LocalPath is DevicePath on native and BrowserPath on web.
 */
export type LocalPath = PlatformSelect<{
  native: DevicePath;
  web: BrowserPath;
}>;

export interface UploadedFile {
  file: ModernFile<LocalPath>;
  storagePath: string;
}

export interface BaseModernFile<T extends FilePath = FilePath> {
  path: T;
  filename: string;
  size: number | undefined;
}

export interface ModernDocumentFile<T extends FilePath = FilePath>
  extends BaseModernFile<T> {
  contentType: "document";
}

export interface ModernImageFile<T extends FilePath = FilePath>
  extends BaseModernFile<T> {
  contentType: "image";
  coordinates?: GeoCoordinates | undefined;
  coordinatesRequestId?: string | undefined;
  quality: ImageQuality;
  caption?: InputPartition[] | undefined;
}

export interface ModernVideoFile<T extends FilePath = FilePath>
  extends BaseModernFile<T> {
  contentType: "video";
  coordinates?: GeoCoordinates | undefined;
  coordinatesRequestId?: string | undefined;
  caption?: InputPartition[] | undefined;
  thumbnail?: LocalPath;
}

export interface ModernAudioFile<T extends FilePath = FilePath>
  extends BaseModernFile<T> {
  contentType: "audio";
  caption?: InputPartition[] | undefined;
}

export type ModernFile<T extends FilePath = FilePath> =
  | ModernDocumentFile<T>
  | ModernAudioFile<T>
  | ModernImageFile<T>
  | ModernVideoFile<T>;

export type ModernFileContentType = ModernFile["contentType"];

export type ModernFileOfType<
  T extends ModernFileContentType,
  Path extends FilePath,
> = Extract<ModernFile<Path>, { contentType: T }>;

export const ModernFileHelper = {
  any<C extends ModernFile["contentType"], T extends FilePath>(
    contentType: C,
    filename: string,
    path: T,
    options?: {
      size?: number;
      quality?: ImageQuality;
    },
  ): ModernFile<T> {
    if (contentType === "image") {
      return {
        contentType,
        filename,
        path,
        size: options?.size ?? 0,
        quality: options?.quality ?? "standard",
      };
    }
    return {
      contentType,
      filename,
      path,
      size: options?.size ?? 0,
    };
  },

  media<T extends FilePath>(
    contentType: "image" | "video",
    filename: string,
    path: T,
    options: {
      size?: ModernImageFile["size"];
      quality: ModernImageFile["quality"];
    },
  ): ModernImageFile<T> | ModernVideoFile<T> {
    return {
      contentType,
      filename,
      path,
      size: options.size,
      quality: options.quality,
    };
  },

  image<T extends FilePath>(
    filename: string,
    path: T,
    options: {
      quality: ModernImageFile["quality"];
      size?: ModernImageFile["size"];
      caption?: ModernImageFile["caption"];
      coordinates?: ModernImageFile["coordinates"];
    },
  ): ModernImageFile<T> {
    return {
      contentType: "image",
      filename,
      path,
      size: options?.size,
      caption: options?.caption,
      coordinates: options?.coordinates,
      quality: options.quality,
    };
  },

  video<T extends FilePath>(
    filename: string,
    path: T,
    options?: {
      size?: ModernVideoFile["size"];
      caption?: ModernVideoFile["caption"];
      coordinates?: ModernVideoFile["coordinates"];
      thumbnail?: ModernVideoFile["thumbnail"];
    },
  ): ModernVideoFile<T> {
    return {
      contentType: "video",
      filename,
      path,
      size: options?.size,
      caption: options?.caption,
      coordinates: options?.coordinates,
      thumbnail: options?.thumbnail,
    };
  },

  document<T extends FilePath>(
    filename: string,
    path: T,
    options?: {
      size?: ModernDocumentFile["size"];
    },
  ): ModernDocumentFile<T> {
    return {
      contentType: "document",
      filename,
      path,
      size: options?.size,
    };
  },

  audio<T extends FilePath>(
    filename: string,
    path: T,
    options?: {
      size?: ModernAudioFile["size"];
      caption?: InputPartition[] | undefined;
    },
  ): ModernAudioFile<T> {
    return {
      contentType: "audio",
      filename,
      path,
      size: options?.size,
      caption: options?.caption,
    };
  },

  isMedia<T extends FilePath>(
    file: ModernFile<T>,
  ): file is ModernImageFile<T> | ModernVideoFile<T> {
    return ["image", "video"].includes(file.contentType);
  },

  getCoordinates(file: ModernFile) {
    return ModernFileHelper.isMedia(file) ? file.coordinates : undefined;
  },

  getText(file: ModernFile) {
    if (
      file.contentType === "image" ||
      file.contentType === "video" ||
      file.contentType === "audio"
    ) {
      return file.caption;
    }
    return undefined;
  },

  getThumbnail<T extends FilePath>(
    file: ModernImageFile<T> | ModernVideoFile<T>,
  ): LocalPath | T | undefined {
    if (file.contentType === "image") {
      return file.path;
    }
    return file.thumbnail;
  },
};

export class FileStorageError extends Error {
  constructor(reason: string) {
    super(`FileStorageError: ${reason}`);
  }
}
