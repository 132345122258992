import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, View } from "react-native";

import { dismissNativeKeyboard } from "@kraaft/helper-functions";
import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { intercom } from "@kraaft/shared/core/modules/intercom/intercom.provider";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import type { UseMapNavigationReturnType } from "@kraaft/shared/core/utils/useMapNavigation/types";
import { Button, Text } from "@kraaft/ui";

import { styles } from "./noMarkers.styles";

interface Props {
  geoDetail: UseMapNavigationReturnType["geoDetail"];
}

export const NoMarkers = ({ geoDetail }: Props) => {
  const { t } = useTranslation();

  const handlePress = useCallback(() => {
    trackEvent({ eventName: "Geolocation Tuto Clicked" });
    intercom
      .openArticle(getEnvironment().INTERCOM.ARTICLES.LOCATE_SOMETHING)
      .catch(console.error);
  }, []);

  return (
    <Pressable
      style={styles.backdrop}
      onPress={dismissNativeKeyboard}
      accessibilityLabel=""
    >
      <View style={styles.backdrop}>
        <View style={styles.box}>
          <Text weight="bold" size="BODY">
            {geoDetail === "rooms"
              ? t("noMarkers.rooms")
              : t("noMarkers.photosAndModularFolders")}
          </Text>
          <View style={styles.buttonContainer}>
            <Button
              variant="QUATERNARY"
              icon="help-circle"
              text={t("noMarkers.howToLocate")}
              width="fit-content"
              onPress={handlePress}
            />
          </View>
        </View>
      </View>
    </Pressable>
  );
};
