export type DatadogConfigFlag = {
  sessionSamplingRate: number;
  sessionReplaySamplingRate: number;
  resourceTracingSamplingRate: number;
};

export const defaultFeatureFlags = {
  SHOW_OUTDATED_VERSION_BANNER: false,
  ENABLE_VISION_CAMERA_ON_ANDROID: false,
  ENABLE_MODULAR_FOLDER_FILL_WITH_AUDIO: false,
  ENABLE_VISION_CAMERA_ON_IOS: false,
  ENTER_ROOM_NAME_VARIANT: "name-with-tip",
  DATADOG_CONFIG: undefined as undefined | DatadogConfigFlag,
  /** update Launch Darkly flag description when adding/removing a value here */
};
