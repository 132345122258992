import { EventChannel, eventChannel } from "redux-saga";
import { put, takeEvery } from "typed-redux-saga/macro";

import {
  LibrarySchemaActions,
  LibrarySchemaStateActions,
} from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import { LibrarySchemaQueries } from "@kraaft/shared/core/modules/librarySchema/operations/librarySchemas.queries";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToLibrarySchemaCountSaga() {
  yield takeCountedDeep(
    LibrarySchemaActions.subscribeToCount,
    LibrarySchemaActions.unsubscribeFromCount,
    subscribe,
    unsubscribe,
    (action) => `${action.payload.companyId}-${action.payload.language}`,
  );
}

function* subscribe(
  register: (channel: EventChannel<number>) => void,
  action: ReturnType<typeof LibrarySchemaActions.subscribe>,
) {
  const { companyId, language } = action.payload;

  const channel = eventChannel<number>((emit) => {
    // let's load once rather than subscribe
    async function loadOnce() {
      emit(
        await LibrarySchemaQueries.getLibrarySchemaCount(companyId, language),
      );
    }

    loadOnce().catch(emit);

    return () => {};
  });

  register(channel);

  yield* takeEvery(channel, receiveLibrarySchemas);
}

function* unsubscribe(channel: EventChannel<number> | undefined) {
  channel?.close();
}

function* receiveLibrarySchemas(count: number) {
  yield* put(LibrarySchemaStateActions.setCount(count));
}
