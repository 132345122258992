import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";

import { isNative } from "@kraaft/helper-functions";
import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { selectUtmParams } from "@kraaft/shared/core/modules/app/appSelector";
import {
  UserActions,
  UserStateActions,
} from "@kraaft/shared/core/modules/user/userActions";
import { firebaseAnalytics } from "@kraaft/shared/core/services/analytics/firebaseAnalytics";
import { Api } from "@kraaft/shared/core/services/api";
import { appsFlyer } from "@kraaft/shared/core/services/appsflyer";
import { facebookSdk } from "@kraaft/shared/core/services/facebook";
import { googleTagManager } from "@kraaft/shared/core/services/googleTagManager/googleTagManager.provider";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useApiError } from "@kraaft/shared/core/utils/useApi.hooks";

export function useCreateOwnWorkspace(
  name: string,
  language: string,
  timeZone: string,
): readonly [() => void, { loading: boolean; poolId: string | undefined }] {
  const dispatch = useDispatch();
  const utmParams = useSelector(selectUtmParams);

  const { isLoading, mutate, error, data } = useMutation(async () => {
    // dispach before api call, because we might not get the result here
    dispatch(
      UserStateActions.updateCurrentUserOnboardingState({
        shouldShowPoolOnboarding: true,
      }),
    );

    trackEvent({
      eventName: "Create Workspace",
      from: isNative() ? "mobile" : "web",
      pool_name: name,
      timeZone,
      ...utmParams,
    });

    const poolId = await Api.createOwnWorkspace(name, language, timeZone);

    const conversionEventDestination =
      getEnvironment().GOOGLE.ANALYTICS.CONVERSION_WORKSPACE_CREATED;
    if (conversionEventDestination) {
      googleTagManager
        .event("conversion", {
          send_to: conversionEventDestination,
          transaction_id: poolId,
        })
        .catch(console.error);
    }

    facebookSdk.logEvent("Create Workspace");
    firebaseAnalytics.logEvent("Create_Workspace_In_App", {
      poolId,
      poolName: name,
    });
    appsFlyer
      .logEvent("Create_Workspace", { poolId, poolName: name })
      .catch(console.error);

    dispatch(UserActions.workspaceCreated());
    return poolId;
  });

  useApiError(error, "serverError");

  return [mutate, { loading: isLoading, poolId: data }];
}
