import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { persistReducer, REHYDRATE } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";

import {
  allStoresRehydrated,
  MemoryStateActions,
} from "@kraaft/shared/core/modules/memory/memoryActions";
import { MemoryState } from "@kraaft/shared/core/modules/memory/memoryState";
import { platformReducers } from "@kraaft/shared/core/modules/memory/platformReducers";
import { storage } from "@kraaft/shared/core/modules/storage/storage.provider";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

export const initialState: MemoryState = {
  allStoresRehydrated: false,
  rehydratedStores: {},
  blockedUserIds: {},
  ssoLinkSkippedAt: undefined,
  deniedSetRoomLocation: {},
};

const memoryReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, (state) => ({
    allStoresRehydrated: state.allStoresRehydrated,
    rehydratedStores: {},
    blockedUserIds: {},
    ssoLinkSkippedAt: undefined,
    deniedSetRoomLocation: {},
  }));

  addCase(allStoresRehydrated, (state) => {
    state.allStoresRehydrated = true;
  });

  // this action is triggered directly by redux-persist.
  addCase(REHYDRATE, (state, action) => {
    if (!("key" in action) || typeof action.key !== "string") {
      return;
    }
    state.rehydratedStores[action.key] = true;
  });

  addCase(MemoryStateActions.addBlockedUser, (state, { payload }) => {
    state.blockedUserIds[payload.userId] = true;
  });

  addCase(MemoryStateActions.setSSOElevationSkipped, (state) => {
    state.ssoLinkSkippedAt = new Date().toISOString();
  });

  addCase(MemoryStateActions.resetSSOElevationSkipped, (state) => {
    state.ssoLinkSkippedAt = undefined;
  });

  addCase(MemoryStateActions.deniedSetRoomLocation, (state, { payload }) => {
    state.deniedSetRoomLocation[payload.roomId] = true;
  });
});

const memoryPersistConfig: PersistConfig<MemoryState> = {
  key: "sharedMemory",
  storage: storage,
  blacklist: ["allStoresRehydrated", "rehydratedStores"],
};

const persistedMemoryReducers = persistReducer(
  memoryPersistConfig,
  memoryReducers,
);

const combinedReducers = combineReducers({
  shared: persistedMemoryReducers,
  platform: platformReducers,
});

export { combinedReducers as memoryReducers };
