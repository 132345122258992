import { PropsWithChildren } from "react";
import { View } from "react-native";
import { makeStyles } from "@mui/styles";

import { BlueGradientBackgroundProps } from "@kraaft/shared/core/modules/surveys/components/blueGradientBackground/blueGradientBackgroundProps";
import { Color, morphClassNameAsStyle } from "@kraaft/ui";

export const BlueGradientBackground = ({
  children,
  style,
}: PropsWithChildren<BlueGradientBackgroundProps>) => {
  const classes = useStyles();

  return (
    <View style={[morphClassNameAsStyle(classes.container), style]}>
      {children}
    </View>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    background: `linear-gradient(63deg, #2F4CC5 12.26%, ${Color.BLUE_AZURE} 79.79%)`,
  },
});
