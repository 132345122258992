import { useCallback, useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { StrictOmit } from "ts-essentials";

import { useMeshContext } from "@kraaft/helper-hooks";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import {
  ButtonElement,
  ButtonElementProps,
} from "@kraaft/web/src/components/layout/verticalNavigationBar/buttonElement";
import { VerticalNavigationContext } from "@kraaft/web/src/components/layout/verticalNavigationBar/verticalNavigation.context";

type NavigationButtonElementProps = StrictOmit<
  ButtonElementProps,
  "onPress" | "isSelected"
> & {
  route: string;
  matchRoute?: string[];
  excludeRoute?: string[];
};

export const NavigationButtonElement = ({
  route,
  matchRoute,
  excludeRoute,
  ...buttonElementProps
}: NavigationButtonElementProps) => {
  const { overrideExpanded } = useMeshContext(VerticalNavigationContext);
  const navigationService = useNavigationService();
  const location = useLocation();
  const matcheableRoutes = useMemo(
    () => [route, ...(matchRoute ?? [])],
    [matchRoute, route],
  );

  const isCurrentRoute = useMemo(() => {
    const currentLocationPath = location.pathname.split("?")[0] ?? "";

    return (
      matcheableRoutes.some((r) => {
        const matcheableRoutePath = r.split("?")[0] ?? "";
        return matchPath(currentLocationPath, matcheableRoutePath);
      }) &&
      !excludeRoute?.some((r) => {
        const matcheableRoutePath = r.split("?")[0] ?? "";
        return matchPath(currentLocationPath, matcheableRoutePath);
      })
    );
  }, [excludeRoute, location.pathname, matcheableRoutes]);

  const handleNavigateTo = useCallback(() => {
    overrideExpanded(false);
    navigationService.navigateToPathname(route);
  }, [navigationService, overrideExpanded, route]);

  return (
    <ButtonElement
      {...buttonElementProps}
      onPress={handleNavigateTo}
      isSelected={isCurrentRoute}
    />
  );
};
