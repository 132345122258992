import { keyBy } from "lodash";
import { EventChannel, eventChannel } from "redux-saga";
import { put, takeEvery } from "typed-redux-saga/macro";

import { LibrarySchemaActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import { OfflineLibrarySchemaStateActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offline";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { LibrarySchemaQueries } from "@kraaft/shared/core/modules/librarySchema/operations/librarySchemas.queries";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToSuperadminLibrarySchemasSaga() {
  yield takeCountedDeep(
    LibrarySchemaActions.subscribeForSuperadmin,
    LibrarySchemaActions.unsubscribeForSuperadmin,
    subscribe,
    unsubscribe,
    (action) => "superadmin",
  );
}

function* subscribe(
  register: (channel: EventChannel<LibrarySchema[]>) => void,
  _action: ReturnType<typeof LibrarySchemaActions.subscribe>,
) {
  const channel = eventChannel<LibrarySchema[]>((emit) =>
    LibrarySchemaQueries.subscribeToSuperadminLibrarySchemas(emit),
  );

  register(channel);

  yield* takeEvery(channel, receiveLibrarySchemas);
}

function* unsubscribe(channel: EventChannel<LibrarySchema[]> | undefined) {
  channel?.close();
}

function* receiveLibrarySchemas(librarySchemas: LibrarySchema[]) {
  yield* put(
    OfflineLibrarySchemaStateActions.receive(
      keyBy(librarySchemas, (schema) => schema.id),
    ),
  );
}
