import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { PlanningWaitingListSheet } from "@kraaft/shared/core/modules/surveys/planningWaitingList/components/planningWaitingList.sheet";
import { PlanningWaitingListSelectors } from "@kraaft/shared/core/modules/surveys/planningWaitingList/redux";
import { withDependencyWrapper } from "@kraaft/shared/core/utils/withDepedencyWrapper";
import { NavigationOnPressButtonElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/navigationOnPressButtonElement";

const PlanningWaitingListNavElement_ = () => {
  const { t } = useTranslation();

  const { open } = PlanningWaitingListSheet.useOrphan({});

  return (
    <NavigationOnPressButtonElement
      onPress={open}
      icon="calendar-date"
      text={t("planning.waitingList.nav")}
      withNewPill
    />
  );
};

export const PlanningWaitingListNavElement = withDependencyWrapper(
  PlanningWaitingListNavElement_,
  () => {
    const shouldShow = useSelector(
      PlanningWaitingListSelectors.selectShouldShow,
    );

    if (!shouldShow) {
      return undefined;
    }

    return {};
  },
);
