import { createSelector } from "reselect";

import { PlanningWaitingListReducerName } from "@kraaft/shared/core/modules/surveys/planningWaitingList/redux/planningWaitingList.reducerName";
import { PlanningWaitingListState } from "@kraaft/shared/core/modules/surveys/planningWaitingList/redux/planningWaitingList.state";
import { StateWith } from "@kraaft/shared/core/store/store/stateWith";

export class PlanningWaitingListSelectors {
  private static selectState = (
    state: StateWith<
      typeof PlanningWaitingListReducerName,
      PlanningWaitingListState
    >,
  ) => state[PlanningWaitingListReducerName];

  static selectShouldShow = createSelector(
    PlanningWaitingListSelectors.selectState,
    (state) => !state.hidden,
  );

  static selectShouldDisplayOnHome = createSelector(
    PlanningWaitingListSelectors.selectState,
    (state) => !state.hideOnHome,
  );
}
