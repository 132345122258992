import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectActor } from "@kraaft/shared/core/modules/auth/actor.selector";
import { selectHasLibrarySchemas } from "@kraaft/shared/core/modules/librarySchema/librarySchema.selectors";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";

import { Guard } from "../auth";
import { LibrarySchemaActions } from "./librarySchema.actions";

/** this hook subscribes to library schemas for the current pool and returns true if it has at least one */
export function useHasLibrarySchemas() {
  const dispatch = useDispatch();

  const pool = useSelector(selectCurrentPool);

  const canListPoolSchema = Guard.use("Schema.listSchemas");

  const hasLibrarySchemas = useSelector(selectHasLibrarySchemas);

  useEffect(() => {
    if (canListPoolSchema && pool) {
      const payload = {
        companyId: pool.companyId,
        language: pool.poolLanguage,
      };
      dispatch(LibrarySchemaActions.subscribeToCount(payload));

      return () => {
        dispatch(LibrarySchemaActions.unsubscribeFromCount(payload));
      };
    }
  }, [canListPoolSchema, dispatch, pool]);

  return canListPoolSchema && hasLibrarySchemas;
}

/** this hook subscribes to library schemas for the current pool, or to all library schemas if actor is support */
export function useSubscribeToLibrarySchemas() {
  const dispatch = useDispatch();
  const pool = useSelector(selectCurrentPool);
  const actor = useSelector(selectActor);
  const canListPoolSchema = Guard.use("Schema.listSchemas");

  const isSuperadmin = actor?.claims.support === true;

  useEffect(() => {
    if (canListPoolSchema && pool) {
      const payload = {
        companyId: pool.companyId,
        language: pool.poolLanguage,
      };
      dispatch(
        isSuperadmin
          ? LibrarySchemaActions.subscribeForSuperadmin()
          : LibrarySchemaActions.subscribe(payload),
      );

      return () => {
        dispatch(
          isSuperadmin
            ? LibrarySchemaActions.unsubscribeForSuperadmin()
            : LibrarySchemaActions.unsubscribe(payload),
        );
      };
    }
  }, [canListPoolSchema, dispatch, isSuperadmin, pool]);
}
