import { useCallback } from "react";

import { useMeshContext } from "@kraaft/helper-hooks";
import { IconName } from "@kraaft/ui";
import { ButtonElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/buttonElement";
import { VerticalNavigationContext } from "@kraaft/web/src/components/layout/verticalNavigationBar/verticalNavigation.context";

interface NavigationOnPressButtonElementProps {
  text: string;
  icon: IconName;
  onPress: () => void;
  withNewPill?: boolean;
}

export const NavigationOnPressButtonElement = ({
  onPress,
  icon,
  text,
  withNewPill,
}: NavigationOnPressButtonElementProps) => {
  const { overrideExpanded } = useMeshContext(VerticalNavigationContext);

  const handleNavigateTo = useCallback(() => {
    overrideExpanded(false);
    onPress();
  }, [onPress, overrideExpanded]);

  return (
    <ButtonElement
      icon={icon}
      text={text}
      onPress={handleNavigateTo}
      withNewPill={withNewPill}
    />
  );
};
