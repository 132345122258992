import { createAction } from "@reduxjs/toolkit";

/**
 * @deprecated This is dispatched for each store rehydrated not when all of them has been rehydrated. Checkout the REHYDRATE action instead.
 */
export const allStoresRehydrated = createAction(
  "@memory/ALL_STORES_REHYDRATED",
);

export const MemoryStateActions = {
  addBlockedUser: createAction<{ userId: string }>("@memory/ADD_BLOCKED_USER"),
  setSSOElevationSkipped: createAction("@memory/SET_SSO_ELEVATION_SKIPPED"),
  resetSSOElevationSkipped: createAction("@memory/RESET_SSO_ELEVATION_SKIPPED"),
  deniedSetRoomLocation: createAction<{ roomId: string }>(
    "@memory/DENIED_SET_ROOM_LOCATION",
  ),
};
