import { createSelector } from "@reduxjs/toolkit";
import memoize from "lodash/memoize";

import { RootState } from "@kraaft/shared/core/store";

export const selectSharedMemoryState = (state: RootState) => {
  return state.memory.shared;
};

export const selectAllStoreRehydrated = createSelector(
  selectSharedMemoryState,
  (state) => state.allStoresRehydrated,
);

export const selectIsStoreRehydrated = memoize((storeName: string) =>
  createSelector(selectSharedMemoryState, (memory) =>
    Boolean(memory.rehydratedStores[storeName]),
  ),
);

export function selectMemoryBlockedUserIds({ memory }: RootState) {
  return memory.shared.blockedUserIds;
}

export function selectHasRecentlySkippedSSO({ memory }: RootState) {
  if (memory.shared.ssoLinkSkippedAt === undefined) {
    return false;
  }

  const skippedAt = new Date(memory.shared.ssoLinkSkippedAt);
  const now = new Date();
  const aDay = 1000 * 60 * 60 * 24;
  const elapsed = now.getTime() - skippedAt.getTime();
  return elapsed <= aDay;
}

export const selectHasDeniedSetRoomLocation = memoize((roomId: string) =>
  createSelector(selectSharedMemoryState, (memory) =>
    Boolean(memory.deniedSetRoomLocation[roomId]),
  ),
);
