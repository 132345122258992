import { PlanningWaitingListReducerName } from "@kraaft/shared/core/modules/surveys/planningWaitingList/redux/planningWaitingList.reducerName";
import { planningWaitingListReducer } from "@kraaft/shared/core/modules/surveys/planningWaitingList/redux/planningWaitingList.reducers";
import { injectReducer } from "@kraaft/shared/core/store";

injectReducer(PlanningWaitingListReducerName, planningWaitingListReducer);

export * from "./planningWaitingList.actions";
export * from "./planningWaitingList.reducers";
export * from "./planningWaitingList.selectors";
export * from "./planningWaitingList.state";
