import { isNative } from "@kraaft/helper-functions";
import { FIRESTORE_VERBOSE } from "@kraaft/shared/constants/global";
import {
  FirestoreError,
  onSnapshot,
  Query,
  QuerySnapshot,
} from "@kraaft/shared/core/services/firebase/modularQuery";

function debounceByArgument<Args extends any[]>(
  fn: (...args: Args) => void,
  shouldDebounce: (...args: Args) => boolean,
  ms: number,
) {
  let currentId: NodeJS.Timeout | undefined;

  return (...args: Args) => {
    const should = shouldDebounce(...args);
    if (!should) {
      if (currentId !== undefined) {
        clearTimeout(currentId);
      }
      return fn(...args);
    }
    if (currentId !== undefined) {
      clearTimeout(currentId);
      currentId = undefined;
    }
    currentId = setTimeout(() => {
      fn(...args);
      currentId = undefined;
    }, ms);
  };
}

export function onSnapshotQuery<T extends Query>(
  message: string,
  query: T,
  onNext: (snapshot: QuerySnapshot) => void,
  onError?: (error: FirestoreError) => void,
  options?: {
    shouldUpdateOnMetadataUpdate?: boolean;
    // Only available on Web, used to wait a bit to see if network provides a snapshot within the delay
    maxWaitMsBeforeServingCache?: number;
  },
) {
  const startDate = new Date();
  if (FIRESTORE_VERBOSE) {
    console.log("subscribing to", message);
  }

  const handler = (snapshot: QuerySnapshot) => {
    if (snapshot) {
      if (FIRESTORE_VERBOSE) {
        const durationMs = new Date().getTime() - startDate.getTime();
        console.log(
          `snapshot received for ${message}: ${snapshot.size} docs cache=${snapshot.metadata.fromCache} duration= ${durationMs / 1000} s, ${JSON.stringify(snapshot).length.toLocaleString()} chars`,
        );
      }
      onNext(snapshot);
    } else {
      if (FIRESTORE_VERBOSE) {
        const durationMs = new Date().getTime() - startDate.getTime();
        console.log(
          `snapshot received for ${message}: ${snapshot} duration= ${durationMs / 1000} s, ${JSON.stringify(snapshot).length.toLocaleString()} chars`,
        );
      }
    }
  };

  const unsubscribe = onSnapshot(
    query,
    {
      includeMetadataChanges: options?.shouldUpdateOnMetadataUpdate ?? false,
    },
    {
      next:
        options?.maxWaitMsBeforeServingCache && !isNative()
          ? debounceByArgument(
              handler,
              (snapshot) => snapshot.metadata.fromCache,
              1000,
            )
          : handler,

      error: (error: FirestoreError) => {
        console.warn(`snapshot error for ${message}`, error);
        onError?.(error);
      },
    },
  );

  return () => {
    if (FIRESTORE_VERBOSE) {
      console.log("unsubscribing to", message);
    }
    unsubscribe();
  };
}
