import { useMemo } from "react";
import { makeStyles } from "@mui/styles";

import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Preloader, Spacing } from "@kraaft/ui";
import { SchemaLibraryThumbnail } from "@kraaft/web/src/views/schemaLibrary/schemaLibraryGrid/schemaLibraryThumbnail";

interface SchemaLibraryGridProps {
  onSelectSchema: (librarySchemaId: string) => void;
  librarySchemas: LibrarySchema[];
  loading: boolean;
}

const SchemaLibraryGrid = ({
  onSelectSchema,
  librarySchemas,
  loading,
}: SchemaLibraryGridProps) => {
  const classes = useStyles();

  const thumbnails = useMemo(
    () =>
      librarySchemas.map((librarySchema) => (
        <SchemaLibraryThumbnail
          key={librarySchema.id}
          librarySchema={librarySchema}
          onSelectSchema={onSelectSchema}
        />
      )),
    [librarySchemas, onSelectSchema],
  );

  return loading ? (
    <Preloader />
  ) : (
    <div className={classes.container}>{thumbnails}</div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, 300px)",
    gridColumnGap: Spacing.S24,
    gridRowGap: Spacing.S24,
    paddingBottom: Spacing.S24,
  },
});

export { SchemaLibraryGrid };
