import { DeclareOfflineFeature } from "@kraaft/offline";
import { addAttachmentOperation } from "@kraaft/shared/core/modules/modularFolder/operations/addAttachment.operation";
import { addAttachmentFromMessageOperation } from "@kraaft/shared/core/modules/modularFolder/operations/addAttachmentFromMessage.operation";
import { addSignatureOperation } from "@kraaft/shared/core/modules/modularFolder/operations/addSignature.operation";
import { autonumberTitlesOperation } from "@kraaft/shared/core/modules/modularFolder/operations/autonumberTitles.operation";
import { createOperation } from "@kraaft/shared/core/modules/modularFolder/operations/create.operation";
import { deleteOperation } from "@kraaft/shared/core/modules/modularFolder/operations/delete.operation";
import { deleteAttachmentOperation } from "@kraaft/shared/core/modules/modularFolder/operations/deleteAttachment.operation";
import { duplicateOperation } from "@kraaft/shared/core/modules/modularFolder/operations/duplicate.operation";
import { editOperation } from "@kraaft/shared/core/modules/modularFolder/operations/edit.operation";
import { fillFromAudioOperation } from "@kraaft/shared/core/modules/modularFolder/operations/fillFromAudio.operation";
import { reorderOperation } from "@kraaft/shared/core/modules/modularFolder/operations/reorder.operation";
import { updateAttachmentGeolocationOperation } from "@kraaft/shared/core/modules/modularFolder/operations/updateAttachmentGeolocation.operation";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { taskManagerUtils } from "@kraaft/shared/core/store/offline/taskManagerUtils.provider";

export const ModularFolderOfflineFeature = DeclareOfflineFeature<ModularFolder>(
  "ModularFolder",
)(
  {
    create: createOperation,
    edit: editOperation,
    autonumberTitles: autonumberTitlesOperation,
    delete: deleteOperation,
    deleteAttachment: deleteAttachmentOperation,
    duplicate: duplicateOperation,
    updateAttachmentGeolocation: updateAttachmentGeolocationOperation,
    addAttachment: addAttachmentOperation,
    addAttachmentFromMessage: addAttachmentFromMessageOperation,
    addSignature: addSignatureOperation,
    reorder: reorderOperation,
    fillFromAudio: fillFromAudioOperation,
  },
  taskManagerUtils.create("ModularFolder"),
);
