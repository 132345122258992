import { createReducer } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";

import { storage } from "@kraaft/shared/core/modules/storage/storage.provider";
import { PlanningWaitingListStateActions } from "@kraaft/shared/core/modules/surveys/planningWaitingList/redux/planningWaitingList.actions";
import { PlanningWaitingListReducerName } from "@kraaft/shared/core/modules/surveys/planningWaitingList/redux/planningWaitingList.reducerName";
import { PlanningWaitingListState } from "@kraaft/shared/core/modules/surveys/planningWaitingList/redux/planningWaitingList.state";

const initialState: PlanningWaitingListState = {
  hideOnHome: false,
  hidden: false,
};

const planningWaitingListReducer_ = createReducer(
  initialState,
  ({ addCase }) => {
    addCase(PlanningWaitingListStateActions.hide, () => ({
      hideOnHome: true,
      hidden: true,
    }));
    addCase(PlanningWaitingListStateActions.hideOnHome, (state) => ({
      ...state,
      hideOnHome: true,
    }));
  },
);

export const planningWaitingListReducer = persistReducer(
  {
    key: PlanningWaitingListReducerName,
    storage: storage,
  },
  planningWaitingListReducer_,
);
