import { keyBy } from "lodash";
import { EventChannel, eventChannel } from "redux-saga";
import { put, takeEvery } from "typed-redux-saga/macro";

import {
  LibrarySchemaActions,
  LibrarySchemaStateActions,
} from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import { OfflineLibrarySchemaStateActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offline";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { LibrarySchemaQueries } from "@kraaft/shared/core/modules/librarySchema/operations/librarySchemas.queries";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToLibrarySchemasSaga() {
  yield takeCountedDeep(
    LibrarySchemaActions.subscribe,
    LibrarySchemaActions.unsubscribe,
    subscribe,
    unsubscribe,
    (action) => `${action.payload.companyId}-${action.payload.language}`,
  );
}

function* subscribe(
  register: (channel: EventChannel<LibrarySchema[]>) => void,
  action: ReturnType<typeof LibrarySchemaActions.subscribe>,
) {
  const { companyId, language } = action.payload;

  yield* put(LibrarySchemaStateActions.setStartFetching());

  const channel = eventChannel<LibrarySchema[]>((emit) =>
    LibrarySchemaQueries.subscribeToLibrarySchemas(companyId, language, emit),
  );

  register(channel);

  yield* takeEvery(channel, receiveLibrarySchemas);
}

function* unsubscribe(channel: EventChannel<LibrarySchema[]> | undefined) {
  channel?.close();
}

function* receiveLibrarySchemas(librarySchemas: LibrarySchema[]) {
  yield* put(
    OfflineLibrarySchemaStateActions.receive(
      keyBy(librarySchemas, (schema) => schema.id),
    ),
  );
}
