import { MouseEventHandler } from "react";
import { detect } from "detect-browser";
import { v4 as uuidv4 } from "uuid";

import CountryCodes from "@kraaft/shared/components/auth/countryPicker/assets/CountryCodes.json";
import { Color } from "@kraaft/ui";
import app from "@kraaft/web/package.json";

export function isAppVisible() {
  return document?.hidden !== true;
}

export function dashEmpty(value: string | undefined | null): string {
  return value || "-";
}

export function formatError(str: string) {
  return str
    .split("-")
    .map((item) => item.charAt(0).toUpperCase() + item.substring(1))
    .join("");
}

const browser = detect();

export function isIE() {
  return browser?.name === "ie";
}

export function isWebMobile() {
  return isWebAndroid() || isWebiOS();
}

export function isWebAndroid() {
  return browser?.os?.substr(0, 7) === "Android";
}

export function isWebiOS() {
  return browser?.os?.substr(0, 3) === "iOS";
}

export function uuid() {
  return uuidv4();
}

const osList = ["Windows", "Linux", "Mac"] as const;
type OS = (typeof osList)[number];

function getOperatingSystem(): OS | undefined {
  for (const os of osList) {
    if (navigator.appVersion.indexOf(os) >= 0) {
      return os;
    }
  }
  return undefined;
}

export function isOSKeyModifierPressed(
  event: React.KeyboardEvent | React.MouseEvent,
) {
  const os = getOperatingSystem();

  return os === "Mac" ? event.metaKey : event.ctrlKey;
}

// Inspired from https://kovart.github.io/dashed-border-generator/
export const generateDashedBorder = ({
  strokeColor = Color.BLACK,
  strokeWidth = 4,
  dashArray = [8],
  dashOffset = 0,
  lineCap = "square",
  borderRadius = 0,
}: {
  strokeColor?: string;
  strokeWidth?: number;
  dashArray?: number[];
  dashOffset?: number;
  lineCap?: "butt" | "round" | "square";
  borderRadius?: number;
}) => ({
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' ${
    borderRadius ? `rx='${borderRadius}' ry='${borderRadius}'` : ""
  } stroke='${encodeURIComponent(
    strokeColor,
  )}' stroke-width='${strokeWidth}' stroke-dasharray='${dashArray.join(
    "%2c ",
  )}' stroke-dashoffset='${dashOffset}' stroke-linecap='${lineCap}'/%3e%3c/svg%3e")`,
});

export function setDocumentTitle(newTitle: string) {
  document.title = newTitle;
}

export function getDeviceTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

// simple version based on navigator language
export function getDeviceCountry() {
  const language = navigator.language;
  const [locale, country] = language.split("-");
  if (country) {
    return country.toUpperCase();
  }

  let countryCode: string | undefined;
  if (locale) {
    countryCode = CountryCodes.find(
      (code) => code.code === locale.toUpperCase(),
    )?.code;
  }
  if (locale && countryCode) {
    return countryCode;
  }
  if (locale === "en") {
    return "US";
  }
  if (locale === "it") {
    return "IT";
  }
  return "FR";
}

export async function getCarrierCountry(): Promise<string | undefined> {
  return getDeviceCountry();
}

export function getCurrentVersion() {
  return app.version;
}

export const stopPropagation: MouseEventHandler<HTMLDivElement> = (event) => {
  event.stopPropagation();
};
