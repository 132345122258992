import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { FillWithAudioButton } from "@kraaft/shared/components/modularFolders/modularFolderDetails/modularFolderDetailsFooter/fillWithAudioButton";
import { useDownloadReport } from "@kraaft/shared/components/modularFolders/modularFolderDetails/modularFolderDetailsFooter/useDownloadReport";
import { useReportTemplateSelection } from "@kraaft/shared/components/reportTemplate/useReportTemplateSelection";
import { useFeatureFlag } from "@kraaft/shared/core/modules/featureFlags/featureFlags.hooks";
import { useNotPersistedModularFolderWarning } from "@kraaft/shared/core/modules/modularFolder/hooks/useNotPersistedModularFolderWarning";
import { ModularFolderId } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { useEnsureOnline } from "@kraaft/shared/core/utils/useNetwork";
import { Button, ColorStyle, Spacing } from "@kraaft/ui";

type ModularFolderDetailsFooterProps = {
  modularFolderId: ModularFolderId;
  schemaId: string;
  portalHostname?: string;
};

export const ModularFolderDetailsFooter = ({
  modularFolderId,
  schemaId,
}: ModularFolderDetailsFooterProps) => {
  const { t } = useTranslation();

  const downloadReportButtonRef = useRef(null);

  const enableModularFolderFillWithAudio = useFeatureFlag(
    "ENABLE_MODULAR_FOLDER_FILL_WITH_AUDIO",
  );

  const { downloadReport, isGenerating } = useDownloadReport({
    schemaId,
    modularFolderId,
  });

  const { ReportTemplateSelection, openReportTemplateSelection } =
    useReportTemplateSelection({
      schemaId: schemaId,
      callback: downloadReport,
      anchorRef: downloadReportButtonRef,
      allowPDF: true,
    });

  const { element, guard: persistGuardOpenReportTemplateSelection } =
    useNotPersistedModularFolderWarning(
      modularFolderId,
      openReportTemplateSelection,
    );

  const onlineGuardOpenReportTemplateSelection = useEnsureOnline(
    persistGuardOpenReportTemplateSelection,
    [persistGuardOpenReportTemplateSelection],
  );

  return (
    <>
      <View style={styles.wrapperStyle}>
        <Button
          id="ide2e-modularFolder-share-export"
          variant="SECONDARY"
          alignContent="center"
          ref={downloadReportButtonRef}
          onPress={onlineGuardOpenReportTemplateSelection}
          text={
            isNative() ? t("shareExportNative") : t("generateFolderExportWeb")
          }
          icon={isNative() ? "share-02" : "download-01"}
          loading={isGenerating}
        />
        {enableModularFolderFillWithAudio && (
          <FillWithAudioButton modularFolderId={modularFolderId} />
        )}
      </View>
      {ReportTemplateSelection}
      {element}
    </>
  );
};

const styles = StyleSheet.create({
  wrapperStyle: {
    padding: Spacing.S16,
    gap: Spacing.S12,
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
  },
});
