import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";

export function incrementPendingOperations(modularFolder: ModularFolder) {
  modularFolder.pending = {
    ...modularFolder.pending,
    operationsCount: (modularFolder?.pending?.operationsCount ?? 0) + 1,
  };
}

export function getModularFolderAttachmentValue(
  modularFolder: ModularFolder,
  columnKey: string | undefined,
) {
  if (!columnKey) {
    const attachmentProperty = Object.values(modularFolder.properties).find(
      (property) => property.columnType === KColumnType.attachment,
    );
    if (attachmentProperty?.columnType !== KColumnType.attachment) {
      return undefined;
    }
    if (!attachmentProperty.value) {
      attachmentProperty.value = [];
    }
    return attachmentProperty.value;
  }
  let property = modularFolder.properties[columnKey];
  if (property && property.columnType !== KColumnType.attachment) {
    return undefined;
  }
  if (!property?.value) {
    property = {
      columnType: KColumnType.attachment,
      value: [],
    };
    modularFolder.properties[columnKey] = property;
  }
  return property.value;
}
