import { compactMap } from "@kraaft/helper-functions";
import { FIRESTORE_VERBOSE } from "@kraaft/shared/constants/global";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import {
  LibrarySchema,
  LibrarySchemaLanguage,
  LibrarySchemaPresentation,
} from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { db } from "@kraaft/shared/core/services/firebase/modularApp";
import {
  collection,
  getCountFromServer,
  onSnapshotQuery,
  query,
  QueryDocumentSnapshot,
  where,
} from "@kraaft/shared/core/services/firebase/modularQuery";
import {
  FirestoreLibrarySchema,
  FirestoreLibrarySchemaPresentation,
} from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { normalizeSchemaSection } from "@kraaft/shared/core/services/firestore/firestoreUtils";
import { parseDate } from "@kraaft/shared/core/services/firestore/parseDate";
import { prepareDownloadUrl } from "@kraaft/shared/core/services/firestore/prepareDownloadUrl";
import { nullId } from "@kraaft/shared/core/utils/utils";

export class LibrarySchemaQueries {
  public static subscribeToLibrarySchemas(
    companyId: string | undefined,
    language: LibrarySchemaLanguage,
    callback: (librarySchemas: LibrarySchema[]) => void,
  ): () => void {
    return onSnapshotQuery(
      "subscribeToLibrarySchemas",
      this.getQuery(companyId, language),
      (snapshot) => {
        const librarySchemas = this.normalizeLibrarySchemas(snapshot.docs);
        callback(librarySchemas);
      },
    );
  }

  public static subscribeToSuperadminLibrarySchemas(
    callback: (librarySchemas: LibrarySchema[]) => void,
  ): () => void {
    return onSnapshotQuery(
      "subscribeToSuperadminLibrarySchemas",
      query(collection(db, "librarySchemas-projection-3n")),
      (snapshot) => {
        const librarySchemas = this.normalizeLibrarySchemas(snapshot.docs);
        callback(librarySchemas);
      },
    );
  }

  public static async getLibrarySchemaCount(
    companyId: string | undefined,
    language: LibrarySchemaLanguage,
  ) {
    const count = (
      await getCountFromServer(this.getQuery(companyId, language))
    ).data().count;
    if (FIRESTORE_VERBOSE) {
      console.log("Loaded library schema count: ", language, companyId, count);
    }
    return count;
  }

  private static getQuery(companyId: string | undefined, language: string) {
    if (companyId === undefined) {
      return query(
        collection(db, "librarySchemas-projection-3n"),
        where("companyId", "==", nullId),
        where("language", "==", language),
        where("enabled", "==", true),
      );
    }

    return query(
      collection(db, "librarySchemas-projection-3n"),
      where("companyId", "in", [nullId, companyId]),
      where("language", "==", language),
      where("enabled", "==", true),
    );
  }

  private static normalizeLibrarySchemas(
    docs: QueryDocumentSnapshot[],
  ): LibrarySchema[] {
    return compactMap(docs, (doc) => {
      const data = doc.data() as FirestoreLibrarySchema;

      return {
        id: doc.id,
        name: data.name,
        icon: data.icon,
        companyId: data.companyId === nullId ? undefined : data.companyId,
        tagIds: data.tags?.map((tag) => tag.id),
        schema: {
          id: doc.id,
          autoIncrement: data.index,
          collection: "folder",
          color: "#000000",
          description: "",
          index: data.index,
          type: "custom",
          poolId: "",
          updatedAt: parseDate(data.updatedAt),
          name: data.name,
          icon: data.icon,
          rootSection: normalizeSchemaSection(data.definition),
          highlightedCheckbox: data.highlightedCheckbox,
          defaultVisibility: "hidden",
        },
        enabled: data.enabled,
        index: data.index,
        presentation: this.normalizeLibrarySchemaPresentation(
          data.presentation,
        ),
        language: data.language,
        reportTemplates:
          data.reportTemplates.map((template, index) => ({
            ...template,
            updatedAt: parseDate(template.updatedAt),
            ...(template.variant === "default"
              ? {
                  variant: "default",
                  format: template.format === "xlsx" ? "xlsx" : "docx",
                }
              : {
                  variant: "custom",
                  name: template.name,
                  file: {
                    filename: template.file.file.filename,
                    downloadUrl: prepareDownloadUrl(
                      fileAllocator.parseRemotePath(
                        template.file.file.downloadUrl,
                      ),
                    ),
                  },
                  format: template.file.format === "xlsx" ? "xlsx" : "docx",
                }),
            index,
          })) || [],
        updatedAt: parseDate(data.updatedAt),
      } satisfies LibrarySchema;
    });
  }

  private static normalizeLibrarySchemaPresentation(
    data: FirestoreLibrarySchemaPresentation,
  ): LibrarySchemaPresentation {
    return {
      description: data.description,
      coverPictureUrl: data.coverPicture
        ? prepareDownloadUrl(data.coverPicture.downloadUrl)
        : null,
      video:
        data.video && data.video.type === "youtube"
          ? {
              type: data.video.type,
              id: data.video.id,
              url: data.video.url,
            }
          : undefined,
      sampleReport: data.sampleReport
        ? {
            filename: data.sampleReport.filename,
            downloadUrl: prepareDownloadUrl(data.sampleReport.downloadUrl),
          }
        : null,
    };
  }
}
