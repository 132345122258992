import keyBy from "lodash/keyBy";
import { EventChannel, eventChannel } from "redux-saga";
import { put, takeEvery } from "typed-redux-saga/macro";

import { LibrarySchemaActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import { OfflineSchemaLibraryTagStateActions } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTag.offline";
import { SchemaLibraryTag } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToSchemaLibraryTagsSaga() {
  yield takeCountedDeep(
    LibrarySchemaActions.subscribe,
    LibrarySchemaActions.unsubscribe,
    subscribe,
    unsubscribe,
    () => "all", // maybe add language subscription one day
  );

  yield takeCountedDeep(
    LibrarySchemaActions.subscribeForSuperadmin,
    LibrarySchemaActions.unsubscribeForSuperadmin,
    subscribe,
    unsubscribe,
    () => "all", // maybe add language subscription one day
  );
}

function* subscribe(
  register: (channel: EventChannel<Array<SchemaLibraryTag>>) => void,
) {
  const channel = eventChannel<Array<SchemaLibraryTag>>((emit) =>
    Firestore.subscribeToSchemaLibraryTags((data) => emit(data)),
  );
  register(channel);

  yield* takeEvery(channel, receiveSchemaLibraryTags);
}

function* unsubscribe(
  channel: EventChannel<Array<SchemaLibraryTag>> | undefined,
) {
  channel?.close();
}
function* receiveSchemaLibraryTags(tags: Array<SchemaLibraryTag>) {
  const tagDict = keyBy(tags, (tag) => tag.id);
  yield* put(OfflineSchemaLibraryTagStateActions.receive(tagDict));
}
